import { useEffect, useState } from 'react'

export default function Modal({
  open,
  close,
  title,
  children,
}: {
  open: boolean
  close: () => void
  title?: string
  children: React.ReactNode
}) {
  const [state, setState] = useState({
    displayed: false,
  })

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
      setState((s) => ({ ...s, displayed: true }))
    } else if (!open) {
      document.body.style.overflow = 'auto'
      setState((s) => ({ ...s, displayed: false }))
    }
  }, [open, state.displayed])

  const handleClose = () => {
    document.body.style.overflow = 'auto'
    close()
  }

  return (
    <div
      className={`fixed z-50 inset-0 overflow-y-auto ${
        state.displayed ? 'pointer-events-auto' : 'pointer-events-none'
      }`}
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className={`fixed inset-0 transition-opacity ease-out duration-300 ${
            state.displayed
              ? 'opacity-100 pointer-events-auto'
              : 'opacity-0 pointer-events-none'
          }`}
          aria-hidden={state.displayed}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          className={`inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 ease-out duration-300 ${
            state.displayed
              ? 'opacity-100 translate-y-0 sm:scale-100 pointer-events-auto'
              : 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95 pointer-events-none'
          }`}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className={`flex ${title ? 'justify-between' : 'justify-end'}`}>
            {title && <div className="font-semibold">{title}</div>}
            <button
              className="text-gray-600 hover:text-gray-700 rounded-full outline-none focus:outline-none focus:rounded-md focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
              onClick={handleClose}
            >
              <svg
                className="w-6 h-6"
                xmlns="https://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </button>
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}
